import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AUTHENTICATION } from './middleware'
import LoginIndex from '@/modules/guests/login/Index.vue'
import DashboardIndex from '@/modules/authenticated/dashboard/Index.vue'
import CustomerVoiceIndex from '@/modules/authenticated/customer-voices/Index.vue'
import WebsiteTypeIndex from '@/modules/authenticated/website-types/Index.vue'
import ProjectTypeIndex from '@/modules/authenticated/project-types/Index.vue'
import ProjectsIndex from '@/modules/authenticated/projects/Index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: LoginIndex,
    beforeEnter: AUTHENTICATION
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardIndex,
    beforeEnter: AUTHENTICATION
  },
  {
    path: '/customer-voices',
    name: 'Customer Voices',
    component: CustomerVoiceIndex,
    beforeEnter: AUTHENTICATION
  },
  {
    path: '/website-types',
    name: 'Website Types',
    component: WebsiteTypeIndex,
    beforeEnter: AUTHENTICATION
  },
  {
    path: '/project-types',
    name: 'Project Types',
    component: ProjectTypeIndex,
    beforeEnter: AUTHENTICATION
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsIndex,
    beforeEnter: AUTHENTICATION
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
