<template>
  <v-card-text>
    <MoleculeTable />
  </v-card-text>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeTable from '../molecules/MoleculeTable.vue'

export default defineComponent({
  components: {
    MoleculeTable
  }
})
</script>
