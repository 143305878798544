import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import { ProjectTypeState } from './types'
import { AxiosResponse } from 'axios'
import instance from '@/plugins/axios'
import routes from '@/utils/routes'
import { Pagination } from '@/store/models/pagination'
import { ProjectType } from '../models/project-type'

const state: ProjectTypeState = {
  projectTypes: [],
  pagination: {} as Pagination
}

const getters: GetterTree<ProjectTypeState, RootState> = {
  getProjectTypes (state): Array<ProjectType> {
    return state.projectTypes
  },
  getPagination (state): Pagination | Record<string, never> {
    return state.pagination
  }
}

const mutations: MutationTree<ProjectTypeState> = {
  SET_PROJECT_TYPES (state, payload: Array<ProjectType>) {
    state.projectTypes = payload
  },
  SET_PAGINATION (state, payload: Pagination) {
    state.pagination = payload
  },
  DELETE_PROJECT_TYPE (state, payload: number) {
    state.projectTypes = state.projectTypes.filter((projectTypes) => projectTypes.id !== payload)
    if (state.pagination) {
      state.pagination.total = (state.pagination.total || 0) - 1
    }
  }
}

const actions: ActionTree<ProjectTypeState, RootState> = {
  async fetchProjectTypes ({ commit }, params: object): Promise<AxiosResponse> {
    const response = await instance.get(routes.projectTypes, { params })
    commit('SET_PROJECT_TYPES', response.data.data)
    commit('SET_PAGINATION', response.data.pagination)
    return response
  },
  async saveProjectType (_, data: ProjectType): Promise<AxiosResponse> {
    const response = !data.id
      ? await instance.post(routes.projectTypes, data)
      : await instance.patch(`${routes.projectTypes}/${data.id}`, data)
    return response
  },
  async deleteProjectType ({ commit }, id: number): Promise<AxiosResponse> {
    const response = await instance.delete(`${routes.projectTypes}/${id}`)
    commit('DELETE_PROJECT_TYPE', id)
    return response
  }
}

export const projectType: Module<ProjectTypeState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
