<template>
  <div>
    <v-layout wrap>
      <v-spacer v-for="item in items"
            :key="item.title">
        <v-card
          class="mx-auto px-1"
          max-width="355"
          outlined
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title >Total {{ item.title }}</v-list-item-title>
              <div class="d-flex justify-space-between">
                <v-list-item-icon >
                  <v-icon :color="color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-progress-circular v-if="isTableLoading"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div v-else :color="color" class="align-self-center text-h6">{{ item.count }}</div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-spacer>
    </v-layout>
    <AtomChart :chartData="chartData" :chartOptions="chartOptions"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import AtomChart from '../atoms/AtomChart.vue'

export default defineComponent({
  components: {
    AtomChart
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      items: [
        { title: 'Customer Voices', icon: 'mdi-account-tie-voice-outline', count: 0 },
        { title: 'Website Types', icon: 'mdi-web', count: 0 },
        { title: 'Project Types', icon: 'mdi-book-information-variant', count: 0 },
        { title: 'Projects', icon: 'mdi-book-outline', count: 0 }
      ],
      chartData:
      {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      chartOptions:
      {
        responsive: true
      }
    }
  },
  async mounted () {
    this.setTableLoading(true)
    await this.fetchTotalCounts()
      .finally(() => {
        this.setTableLoading(false)
        this.mapCount()
        this.generateChartData(this.chartData, this.getDashboard.projectsPerTypeCount)
      })
  },
  computed: {
    ...mapGetters({
      getDashboard: 'dashboard/getDashboard',
      isTableLoading: 'global/isTableLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchTotalCounts: 'dashboard/fetchTotalCounts',
      setTableLoading: 'global/setTableLoading'
    }),
    mapCount () {
      this.items = this.items.map(item => {
        switch (item.title) {
          case 'Customer Voices':
            return { ...item, count: this.getDashboard.customerVoiceCount }
          case 'Website Types':
            return { ...item, count: this.getDashboard.websiteTypeCount }
          case 'Project Types':
            return { ...item, count: this.getDashboard.projectTypeCount }
          case 'Projects':
            return { ...item, count: this.getDashboard.projectCount }
          default:
            return item
        }
      })
    },
    generateRandomColor () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    generateChartData (chartData: any, projectsPerTypeCount: Array<any>) {
      projectsPerTypeCount.map(projectType => {
        chartData.labels.push(projectType.name)
        chartData.datasets[0].data.push(projectType.projects_count)
        chartData.datasets[0].backgroundColor.push(this.generateRandomColor())
        return projectType
      })
      this.chartData = chartData
    }
  }
})
</script>
