import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { GlobalState } from './type'
import { RootState } from '../types'

const state: GlobalState = {
  pageLoading: false,
  tableLoading: false,
  drawer: true
}

const getters: GetterTree<GlobalState, RootState> = {
  isPageLoading (state): boolean {
    return state.pageLoading
  },
  isTableLoading (state): boolean {
    return state.tableLoading
  },
  isDrawerOpen (state): boolean {
    return state.drawer
  }
}

const mutations: MutationTree<GlobalState> = {
  SET_PAGE_LOADING (state, payload: boolean) {
    state.pageLoading = payload
  },
  SET_TABLE_LOADING (state, payload: boolean) {
    state.tableLoading = payload
  },
  SET_DRAWER (state, payload: boolean) {
    state.drawer = payload
  }
}

const actions: ActionTree<GlobalState, RootState> = {
  setPageLoading ({ commit }, payload): boolean {
    commit('SET_PAGE_LOADING', payload)
    return payload
  },
  setTableLoading ({ commit }, payload): boolean {
    commit('SET_TABLE_LOADING', payload)
    return payload
  },
  setDrawer ({ commit }, payload): boolean {
    commit('SET_DRAWER', payload)
    return payload
  }
}

export const global: Module<GlobalState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
