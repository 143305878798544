<template>
  <v-data-table
    :headers="headers"
    :items="getWebsiteTypes"
    :options.sync="options"
    :items-per-page="getPagination.perPage"
    :server-items-length="getPagination.total"
    :loading="isTableLoading"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-options': [6, 12, 18, 24, 30]
    }"
    class="elevation-1"
  >
    <template v-slot:[`item.created_at`]="{ item }">
      <span>{{ formatDate(item.created_at) }}</span>
    </template>
    <template v-slot:[`item.updated_at`]="{ item }">
      <span>{{ formatDate(item.updated_at) }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-container class="p-2">
        <molecule-save
          :icon="'mdi-pencil'"
          :color="'success'"
          :editWebsiteType="item"
        />
        <v-btn
          color="error"
          fab
          small
          dark
          @click="deleteWebsiteTypeById(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-container>
    </template>
  </v-data-table>
</template>
<script lang="ts">
import routes from '@/utils/routes'
import moment from 'moment'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import MoleculeSave from './MoleculeSave.vue'

export default defineComponent({
  components: { MoleculeSave },
  data () {
    return {
      baseUrl: routes.baseUrl,
      options: {},
      search: '',
      headers: [
        { text: 'URL', value: 'url', width: '200', sortable: false, align: 'center' },
        { text: 'Domain Name', value: 'domain_name', sortable: false, align: 'center' },
        { text: 'Posted At', value: 'created_at', sortable: false, align: 'center' },
        { text: 'Updated At', value: 'updated_at', sortable: false, align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      websiteTypes: []
    }
  },
  watch: {
    options: {
      async handler (data) {
        const { page, itemsPerPage } = data
        if (data) {
          const params = {
            page: page,
            limit: itemsPerPage
          }
          this.setTableLoading(true)
          await this.fetchWebsiteTypes(params)
            .finally(() => {
              this.setTableLoading(false)
            })
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      getWebsiteTypes: 'websiteType/getWebsiteTypes',
      getPagination: 'websiteType/getPagination',
      isTableLoading: 'global/isTableLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchWebsiteTypes: 'websiteType/fetchWebsiteTypes',
      createWebsiteTypes: 'websiteType/createWebsiteTypes',
      deleteWebsiteType: 'websiteType/deleteWebsiteType',
      setTableLoading: 'global/setTableLoading'
    }),
    formatDate: (payload: string): string => {
      return moment(payload).format('LLL')
    },
    async deleteWebsiteTypeById (id: number): Promise<void> {
      this.setTableLoading(true)
      await this.deleteWebsiteType(id)
        .finally(() => {
          this.setTableLoading(false)
        })
    }
  }
})
</script>
