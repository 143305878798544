import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import { ProjectState } from './types'
import { AxiosResponse } from 'axios'
import instance from '@/plugins/axios'
import routes from '@/utils/routes'
import { Pagination } from '@/store/models/pagination'
import { Project } from '../models/projects'

const state: ProjectState = {
  projects: [],
  pagination: {} as Pagination
}

const getters: GetterTree<ProjectState, RootState> = {
  getProjects (state): Array<Project> {
    return state.projects
  },
  getPagination (state): Pagination | Record<string, never> {
    return state.pagination
  }
}

const mutations: MutationTree<ProjectState> = {
  SET_PROJECTS (state, payload: Array<Project>) {
    state.projects = payload
  },
  SET_PAGINATION (state, payload: Pagination) {
    state.pagination = payload
  },
  DELETE_PROJECT (state, payload: number) {
    state.projects = state.projects.filter((project) => project.id !== payload)
    if (state.pagination) {
      state.pagination.total = (state.pagination.total || 0) - 1
    }
  }
}

const actions: ActionTree<ProjectState, RootState> = {
  async fetchProjects ({ commit }, params: object): Promise<AxiosResponse> {
    const response = await instance.get(routes.projects, { params })
    commit('SET_PROJECTS', response.data.data)
    commit('SET_PAGINATION', response.data.pagination)
    return response
  },
  async saveProject (_, data: FormData): Promise<AxiosResponse> {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const projectId = data.get('id')
    const id = projectId ? projectId.toString() : ''
    const url = id ? `${routes.projects}/${id}` : routes.projects

    if (id) {
      data.append('_method', 'PATCH')
    }
    const response = await instance.post(url, data, headers)
    return response
  },
  async deleteProject ({ commit }, id: number): Promise<AxiosResponse> {
    const response = await instance.delete(`${routes.projects}/${id}`)
    commit('DELETE_PROJECT', id)
    return response
  }
}

export const project: Module<ProjectState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
