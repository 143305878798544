import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vee-validate'
import '@/plugins/element'
import '@/plugins/vuemoment'

Vue.config.productionTip = false

require('./store/subscriber')

store.dispatch('user/attempt', localStorage.getItem('token'))
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
