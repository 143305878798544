<template>
  <v-card>
    <v-form
      ref="form"
      lazy-validation
      aria-autocomplete="off"
    >
      <AtomToolBar
        :color="toolbarColor"
        @dialog="updateDialog"
        @submit="(data) => $emit('submit', data)"
      />
      <v-card-text>
        <div class="py-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label>Website Type</label>
              <v-autocomplete
                name="website_type_id"
                v-model="projectType.websiteTypeId"
                :loading="isLoading"
                :items="getWebsiteTypes"
                :search-input.sync="search"
                clearable
                item-text="domain_name"
                item-value="id"
                label=""
                dense
                outlined
                cache-items
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search Website Types
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:[`item`]="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="text-h5 font-weight-light white--text"
                  >
                    {{ item.domain_name.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.domain_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.url }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label>Name</label>
              <v-text-field
                v-model="projectType.name"
                label=""
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AtomToolBar from './AtomToolBar.vue'
import { mapActions, mapGetters } from 'vuex'
import { ProjectType } from '@/store/models/project-type'

export default defineComponent({
  components: {
    AtomToolBar
  },
  props: {
    toolbarColor: {
      type: String,
      default: 'primary'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    editProjectType: {
      type: Object as () => ProjectType | null
    }
  },
  watch: {
    projectType: {
      handler: function (newVal: ProjectType) {
        this.$emit('projectType', newVal)
      },
      deep: true,
      immediate: true
    },
    editProjectType: {
      handler: async function (newVal) {
        if (newVal) {
          this.projectType.id = newVal.id
          this.projectType.websiteTypeId = newVal.website_type_id
          this.projectType.name = newVal.name
        }
      },
      deep: true,
      immediate: true
    },
    search: {
      handler: async function (newVal) {
        const params = {
          search: newVal
        }
        this.isLoading = true
        await this.fetchWebsiteTypes(params)
          .finally(() => {
            this.isLoading = false
          })
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      projectType: {} as ProjectType,
      isLoading: false,
      search: null
    }
  },
  computed: {
    ...mapGetters({
      getWebsiteTypes: 'websiteType/getWebsiteTypes'
    })
  },
  methods: {
    ...mapActions({
      fetchWebsiteTypes: 'websiteType/fetchWebsiteTypes',
      createProjectType: 'projectType/createProjectType'
    }),
    updateDialog (data: boolean): void {
      this.$emit('dialog', data)
    }
  }
})
</script>
