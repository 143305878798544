import axios, { AxiosInstance } from 'axios'

const baseUri = process.env.VUE_APP_BACKEND_URI
const instance: AxiosInstance = axios.create({
  baseURL: baseUri,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default instance
