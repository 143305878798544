<template>
  <v-dialog
    v-model="dialog"
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        fab
        small
        dark
        class="mx-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <AtomDialogForm
      :editProjectType="editProjectType || null"
      :toolbarColor="color"
      :dialog="dialog"
      v-if="dialog"
      @dialog="(data) => dialog = data"
      @projectType="(data) => projectType = data"
      @submit="submitForm"
    />
  </v-dialog>
</template>

<script lang="ts">
import AtomDialogForm from '../atoms/AtomDialogForm.vue'
import { defineComponent } from 'vue'
import { ProjectType } from '@/store/models/project-type'
import { mapActions, mapGetters } from 'vuex'
import { toSnakeCase } from '@/utils/string-manipulation'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    color: {
      type: String,
      default: 'primary'
    },
    editProjectType: {
      type: Object as () => ProjectType | null
    }
  },
  components: {
    AtomDialogForm
  },
  data () {
    return {
      dialog: false,
      projectType: {} as ProjectType
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'projectType/getPagination'
    })
  },
  methods: {
    ...mapActions({
      setTableLoading: 'global/setTableLoading',
      setPageLoading: 'global/setPageLoading',
      fetchProjectTypes: 'projectType/fetchProjectTypes',
      saveProjectType: 'projectType/saveProjectType'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appendFormData (data: ProjectType): Record<string, any> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formData: Record<string, any> = {}
      for (const [key, value] of Object.entries(data)) {
        const snakeCaseKey = toSnakeCase(key)
        formData[snakeCaseKey] = value
      }
      return formData
    },
    submitForm (): void {
      const formData = this.appendFormData(this.projectType)

      this.setPageLoading(true)
      this.saveProjectType(formData)
        .then(() => {
          this.dialog = false
          this.setTableLoading(true)
          this.fetchProjectTypes(this.getPagination)
            .finally(() => {
              this.setTableLoading(false)
            })
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = Object.values(error.response.data.errors).flat()
            const errorMessage = errorMessages.join('<br/>')
            this.$message({
              dangerouslyUseHTMLString: true,
              message: errorMessage,
              type: 'error'
            })
          } else {
            console.error('An error occurred:', error)
          }
        })
        .finally(() => {
          this.setPageLoading(false)
        })
    }
  }
})
</script>
