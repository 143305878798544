<template>
  <v-app id="inspire">
    <v-overlay
      :value="isPageLoading"
      class="align-center justify-center"
      z-index="1000"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div v-if="isAuthenticated">
      <NavigationBar />
      <SideBar />
      <v-main>
        <v-container class="py-8 px-6" fluid>
          <router-view />
        </v-container>
      </v-main>
    </div>
    <div v-else>
      <v-main>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script lang="ts">
import NavigationBar from '@/components/layouts/NavigationBar.vue'
import SideBar from '@/components/layouts/SideBar.vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    NavigationBar,
    SideBar
  },
  computed: {
    ...mapGetters({
      isPageLoading: 'global/isPageLoading',
      isDrawerOpen: 'global/isDrawerOpen',
      isAuthenticated: 'user/isAuthenticated'
    })
  }
})
</script>
