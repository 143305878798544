import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import routes from '@/utils/routes'
import { RootState } from '../types'
import { UserState } from './types'
import { User } from '../models/user'
import instance from '@/plugins/axios'
import router from '@/router'

const state: UserState = {
  user: null,
  token: null
}

const getters: GetterTree<UserState, RootState> = {
  getUser (state): User | null {
    return state.user
  },
  isAuthenticated (state): string | null {
    return state.token
  }
}

const mutations: MutationTree<UserState> = {
  SET_USER (state, user: User): void {
    state.user = user
  },
  SET_TOKEN (state, token: string): void {
    state.token = token
  }
}

const actions: ActionTree<UserState, RootState> = {
  async login ({ dispatch }, credentials: { email: string, password: string }) {
    const response = await instance.post(routes.login, credentials)
    dispatch('attempt', response.data.data.token)
    return response
  },
  async attempt ({ commit }, token) {
    if (!token) return
    commit('SET_TOKEN', token)

    try {
      const response = await instance.get(routes.me)
      commit('SET_USER', response.data.data)
    } catch (e) {
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
    }
  },
  async logout ({ commit }) {
    const response = await instance.post(routes.logout)
    commit('SET_USER', null)
    commit('SET_TOKEN', null)
    router.push({ name: 'Login' })
    return response
  }
}

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
