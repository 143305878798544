import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import routes from '@/utils/routes'
import { RootState } from '../types'
import { DashboardState } from './types'
import { Dashboard } from '../models/dashboard'
import { AxiosResponse } from 'axios'
import instance from '@/plugins/axios'

const state: DashboardState = {
  totalCounts: []
}

const getters: GetterTree<DashboardState, RootState> = {
  getDashboard (state): Array<Dashboard> {
    return state.totalCounts
  }
}

const mutations: MutationTree<DashboardState> = {
  SET_TOTAL_COUNTS (state, payload: Array<Dashboard>) {
    state.totalCounts = payload
  }
}

const actions: ActionTree<DashboardState, RootState> = {
  async fetchTotalCounts ({ commit }): Promise<AxiosResponse> {
    const response = await instance.get(routes.dashboard)
    commit('SET_TOTAL_COUNTS', response.data.data)
    return response
  }
}

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
