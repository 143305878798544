<template>
  <v-data-table
      :headers="headers"
      :items="getProjects"
      :options.sync="options"
      :items-per-page="getPagination.perPage"
      :server-items-length="getPagination.total"
      :loading="isTableLoading"
      loading-text="Loading... Please wait"
      :footer-props="{
        'items-per-page-options': [6, 12, 18, 24, 30]
      }"
      class="elevation-1"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formatDate(item.created_at) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-container class="p-2">
          <molecule-save
            :icon="'mdi-pencil'"
            :color="'success'"
            :editProject="item"
          />
          <v-btn
            color="error"
            fab
            small
            dark
            @click="deleteProjectTypeById(item.id)"
          >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        </v-container>
      </template>
    </v-data-table>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import routes from '@/utils/routes'
import MoleculeSave from './MoleculeSave.vue'

export default defineComponent({
  name: 'ProjectsIndex',
  components: { MoleculeSave },
  data () {
    return {
      baseUrl: routes.baseUrl,
      loading: false,
      options: {},
      search: '',
      headers: [
        { text: 'ID', value: 'id', sortable: true, align: 'center' },
        { text: 'Title', value: 'title', sortable: false, align: 'center' },
        { text: 'Project Type', value: 'project_type_id', sortable: false, align: 'center' },
        { text: 'Posted At', value: 'created_at', sortable: false, align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      projects: []
    }
  },
  watch: {
    options: {
      async handler (data) {
        const { page, itemsPerPage } = data
        if (data) {
          const params = {
            page: page,
            limit: itemsPerPage
          }
          this.setTableLoading(true)
          await this.fetchProjects(params)
            .finally(() => {
              this.setTableLoading(false)
            })
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      getProjects: 'project/getProjects',
      getPagination: 'project/getPagination',
      isTableLoading: 'global/isTableLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchProjects: 'project/fetchProjects',
      deleteProject: 'project/deleteProject',
      setTableLoading: 'global/setTableLoading'
    }),
    formatDate: (payload: string): string => {
      return moment(payload).format('LLL')
    },
    async deleteProjectTypeById (id: number): Promise<void> {
      this.setTableLoading(true)
      await this.deleteProject(id)
        .finally(() => {
          this.setTableLoading(false)
        })
    }
  }
})
</script>
