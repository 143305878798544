<template>
  <v-card>
    <OrganismHeader />
    <OrganismContent />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrganismHeader from './organisms/OrganismHeader.vue'
import OrganismContent from './organisms/OrganismContent.vue'

export default defineComponent({
  name: 'WebsiteTypeIndex',
  components: {
    OrganismHeader,
    OrganismContent
  }
})
</script>
