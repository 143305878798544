<template>
  <v-card>
    <v-form
      ref="form"
      lazy-validation
      aria-autocomplete="off"
    >
      <AtomToolBar
        :color="toolbarColor"
        @dialog="updateDialog"
        @submit="(data) => $emit('submit', data)"
      />
      <v-card-text>
        <div class="py-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label>URL</label>
              <v-text-field
                v-model="websiteType.url"
                label=""
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label>Domain Name</label>
              <v-text-field
                v-model="websiteType.domainName"
                label=""
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            </v-row>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AtomToolBar from './AtomToolBar.vue'
import { mapActions } from 'vuex'
import { WebsiteType } from '@/store/models/website-type'

export default defineComponent({
  components: {
    AtomToolBar
  },
  props: {
    toolbarColor: {
      type: String,
      default: 'primary'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    editWebsiteType: {
      type: Object as () => WebsiteType | null
    }
  },
  watch: {
    websiteType: {
      handler: function (newVal: WebsiteType) {
        this.$emit('websiteType', newVal)
      },
      deep: true,
      immediate: true
    },
    editWebsiteType: {
      handler: async function (newVal) {
        if (newVal) {
          this.websiteType.id = newVal.id
          this.websiteType.url = newVal.url
          this.websiteType.domainName = newVal.domain_name
        }
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      websiteType: {} as WebsiteType
    }
  },
  methods: {
    ...mapActions({
      createWebsiteType: 'websiteType/createWebsiteType',
      setLoading: 'global/setLoading'
    }),
    updateDialog (data: boolean): void {
      this.$emit('dialog', data)
    }
  }
})
</script>
