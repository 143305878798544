<template>
  <v-sheet class="mx-auto">
    <validation-observer ref="observer">
      <v-form
        ref="form"
        lazy-validation
        aria-autocomplete="off"
        @submit.prevent="submit"
      >
        <validation-provider
          name="email"
          rules="required"
          v-slot="{ errors }"
          vid="email"
        >
          <v-text-field
            label="Email Address"
            name="email"
            v-model="credentials.email"
            autofocus
            autocomplete="new"
            :error-messages="errors"
            class="change-text-font"
          ></v-text-field>
        </validation-provider>
        <validation-provider
          name="Password"
          rules="required"
          v-slot="{ errors }"
          vid="password"
        >
          <v-text-field
            label="Password"
            name="password"
            v-model="credentials.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new"
            @click:append="showPassword = !showPassword"
            :error-messages="errors"
          ></v-text-field>
        </validation-provider>
        <v-btn class="mt-2" type="submit" block>Submit</v-btn>
      </v-form>
    </validation-observer>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import axios from 'axios'

export default defineComponent({
  data () {
    return {
      showPassword: false,
      credentials: {
        email: '',
        password: ''
      } as { email: string, password: string }
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      setPageLoading: 'global/setPageLoading'
    }),

    async submit () {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await observer.validate()

      if (isValid) {
        this.setPageLoading(true)
        await this.login(this.credentials)
          .then(() => {
            this.$router.push({ name: 'Dashboard' })
          })
          .catch((_error) => {
            if (axios.isAxiosError(_error)) {
              const errorMessage = _error?.response?.data.error.message
              observer.setErrors({
                email: [errorMessage]
              })
            }
          })
          .finally(() => {
            this.setPageLoading(false)
          })
      }
    }
  }
})
</script>
