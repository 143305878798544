<template>
  <v-app-bar app class="primary">
    <v-app-bar-nav-icon @click="setDrawer(!isDrawerOpen)" class="white--text"></v-app-bar-nav-icon>
    <v-toolbar-title></v-toolbar-title>
    <v-spacer />
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="mx-2 white--text"
          icon
          large
          v-on="on"
        >
          <v-icon dark>
            mdi-cog
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-avatar
                color="primary"
                size="45"
              >
                <span class="white--text text-h5">{{ getInitials(user.name) }}</span>
              </v-avatar>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  data () {
    return {
      menu: false,
      selectedItem: null,
      items: [
        { text: 'Profile (soon)', icon: 'mdi-account' },
        { text: 'Logout', icon: 'mdi-logout' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isDrawerOpen: 'global/isDrawerOpen',
      user: 'user/getUser'
    })
  },
  watch: {
    selectedItem: {
      handler: async function (newVal) {
        const logout = 1
        if (newVal === logout) {
          this.setPageLoading(true)
          await this.logout()
            .finally(() => {
              this.setPageLoading(false)
            })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setDrawer: 'global/setDrawer',
      setPageLoading: 'global/setPageLoading',
      logout: 'user/logout'
    }),
    getInitials (str: string) {
      return str
        .trim()
        .split(/\s+/)
        .map(word => word[0])
        .join('')
    }
  }
})
</script>
