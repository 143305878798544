<template>
  <v-toolbar
    dark
    :color="color"
  >
    <v-btn
      icon
      dark
      @click="updateDialog(false)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>Save Website Type</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn
        dark
        text
        @click="$emit('submit', true)"
      >
        Submit
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    updateDialog (data: boolean): void {
      this.$emit('dialog', data)
    }
  }
})
</script>
