import instance from '@/plugins/axios'
import store from '@/store'

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'user/SET_TOKEN':
      if (mutation.payload) {
        instance.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`
        localStorage.setItem('token', mutation.payload)
      } else {
        instance.defaults.headers.common.Authorization = null
        localStorage.setItem('token', '')
      }
      break
  }
})
