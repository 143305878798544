import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import { WebsiteTypeState } from './types'
import { AxiosResponse } from 'axios'
import instance from '@/plugins/axios'
import routes from '@/utils/routes'
import { Pagination } from '@/store/models/pagination'
import { WebsiteType } from '../models/website-type'

const state: WebsiteTypeState = {
  websiteTypes: [],
  pagination: {} as Pagination
}

const getters: GetterTree<WebsiteTypeState, RootState> = {
  getWebsiteTypes (state): Array<WebsiteType> {
    return state.websiteTypes
  },
  getPagination (state): Pagination | Record<string, never> {
    return state.pagination
  }
}

const mutations: MutationTree<WebsiteTypeState> = {
  SET_WEBSITE_TYPES (state, payload: Array<WebsiteType>) {
    state.websiteTypes = payload
  },
  SET_PAGINATION (state, payload: Pagination) {
    state.pagination = payload
  },
  DELETE_WEBSITE_TYPE (state, payload: number) {
    state.websiteTypes = state.websiteTypes.filter((websiteTypes) => websiteTypes.id !== payload)
    if (state.pagination) {
      state.pagination.total = (state.pagination.total || 0) - 1
    }
  }
}

const actions: ActionTree<WebsiteTypeState, RootState> = {
  async fetchWebsiteTypes ({ commit }, params: object): Promise<AxiosResponse> {
    const response = await instance.get(routes.websiteTypes, { params })
    commit('SET_WEBSITE_TYPES', response.data.data)
    commit('SET_PAGINATION', response.data.pagination)
    return response
  },
  async saveWebsiteType (_, data: WebsiteType): Promise<AxiosResponse> {
    const response = !data.id
      ? await instance.post(routes.websiteTypes, data)
      : await instance.patch(`${routes.websiteTypes}/${data.id}`, data)
    return response
  },
  async deleteWebsiteType ({ commit }, id: number): Promise<AxiosResponse> {
    const response = await instance.delete(`${routes.websiteTypes}/${id}`)
    commit('DELETE_WEBSITE_TYPE', id)
    return response
  }
}

export const websiteType: Module<WebsiteTypeState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
