<template>
  <v-card-title>
    <molecule-save />
    <v-spacer></v-spacer>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeSave from '../molecules/MoleculeSave.vue'

export default defineComponent({
  components: {
    MoleculeSave
  },
  data () {
    return {
      search: ''
    }
  }
})
</script>
