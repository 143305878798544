<template>
  <v-card>
    <v-form
      ref="form"
      lazy-validation
      aria-autocomplete="off"
    >
      <AtomToolBar
        :color="toolbarColor"
        @dialog="updateDialog"
        @submit="(data) => $emit('submit', data)"
      />
      <v-card-text>
        <div class="py-5">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <label>Website Type</label>
              <v-autocomplete
                name="website_type_id"
                v-model="customerVoice.websiteTypeId"
                :loading="isLoading"
                :items="getWebsiteTypes"
                :search-input.sync="search"
                clearable
                item-text="domain_name"
                item-value="id"
                label=""
                dense
                outlined
                cache-items
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search Website Types
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:[`item`]="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="text-h5 font-weight-light white--text"
                  >
                    {{ item.domain_name.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.domain_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.url }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <label>Title</label>
              <v-text-field
                v-model="customerVoice.title"
                label=""
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label>Position</label>
              <v-text-field
                v-model="customerVoice.position"
                label=""
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Content</label>
              <ElementTiptap
                @content="(data) => customerVoice.content = data"
                :editContent="editCustomerVoice?.content ?? ''"
              />
            </v-col>
            <v-col cols="12">
              <label>Featured Image</label>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleReplacingImage"
                :auto-upload="false"
                :file-list="fileList"
                :limit="1"
                list-type="picture"
                drag
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                <div class="el-upload__tip" slot="tip">File of type: jpeg, png, jpg, gif, webp.</div>
              </el-upload>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AtomToolBar from './AtomToolBar.vue'
import ElementTiptap from '@/components/plugins/ElementTiptap.vue'
import { mapActions, mapGetters } from 'vuex'
import { CustomerVoice } from '@/store/models/customer-voice'
import routes from '@/utils/routes'
import instance from '@/plugins/axios'

export default defineComponent({
  components: {
    AtomToolBar,
    ElementTiptap
  },
  props: {
    toolbarColor: {
      type: String,
      default: 'primary'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    editCustomerVoice: {
      type: Object as () => CustomerVoice | null
    }
  },
  watch: {
    customerVoice: {
      handler: function (newVal: CustomerVoice) {
        this.$emit('customerVoice', newVal)
      },
      deep: true,
      immediate: true
    },
    editCustomerVoice: {
      handler: async function (newVal) {
        if (newVal) {
          const fetchImage = await this.getImage(newVal.featured_image)
          this.customerVoice.id = newVal.id
          this.customerVoice.websiteTypeId = newVal.website_type_id
          this.customerVoice.title = newVal.title
          this.customerVoice.position = newVal.position
          this.customerVoice.content = newVal.content
          this.customerVoice.featuredImage = fetchImage
          this.fileList = [{
            name: newVal.featured_image?.file_name,
            url: `${routes.baseUrl}/storage/${newVal.featured_image?.path}`
          }] as Array<object>
        }
      },
      deep: true,
      immediate: true
    },
    search: {
      handler: async function (newVal) {
        const params = {
          search: newVal
        }
        this.isLoading = true
        await this.fetchWebsiteTypes(params)
          .finally(() => {
            this.isLoading = false
          })
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      fileList: [] as Array<object>,
      customerVoice: {} as CustomerVoice,
      allowedMimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
      search: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getWebsiteTypes: 'websiteType/getWebsiteTypes'
    })
  },
  methods: {
    ...mapActions({
      fetchWebsiteTypes: 'websiteType/fetchWebsiteTypes',
      createCustomerVoice: 'customerVoice/createCustomerVoice',
      setLoading: 'global/setLoading'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getImage (image: any): Promise<File | null> {
      const imageFile = await this.fetchImage(image.path)

      return imageFile
    },
    async fetchImage (imagePath: string) {
      try {
        const response = await instance.get(`${routes.image}`, {
          responseType: 'blob',
          params: {
            imagePath: imagePath
          }
        })

        if (response.status === 200) {
          const blob = response.data

          if (this.allowedMimeTypes.includes(blob.type)) {
            const imageFile = new File([blob], imagePath, { type: blob.type })

            return imageFile
          } else {
            console.error('Fetched image has an invalid MIME type')
            return null
          }
        } else {
          console.error('Failed to fetch image:', response.status)
          return null
        }
      } catch (error) {
        console.error('Error fetching image:', error)
        return null
      }
    },
    updateDialog (data: boolean): void {
      this.$emit('dialog', data)
    },
    handleReplacingImage (): void {
      this.$message({
        message: 'Remove the current feature image before replacing it!',
        type: 'warning'
      })
    },
    handleChange (file: {
      type: string
      size: number
      raw: File
    }): void {
      const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      const isAllowedType = allowedMimeTypes.includes(file.raw.type)
      const isLt10MB = file.raw.size <= 10000000

      this.customerVoice.featuredImage = file.raw ?? null

      if (!isAllowedType || !isLt10MB) {
        this.$message.error(
          !isAllowedType ? 'Only JPEG, PNG, Webp and GIF files are allowed!' : 'File size can not exceed 10MB!'
        )
        this.customerVoice.featuredImage = null
        this.fileList = []
      }
    },
    handleRemove (): void {
      this.customerVoice.featuredImage = null
    }
  }
})
</script>

<style>
.v-application ul, .v-application ol {
  padding-left: 0px;
}

.el-upload, .el-upload-dragger{
  width: 100% !important;
}

.el-tiptap-editor__menu-bar, .el-tiptap-editor{
  border: 1px solid #a19b9b;
}

.el-upload-dragger {
  border: 2px dashed #a19b9b !important;
}

.el-tiptap-editor__content {
  border: 1px solid #a19b9b !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
</style>
