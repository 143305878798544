<template>
  <div>
    <v-row class="pt-2">
      <v-col v-for="(field, index) in fields" :key="index" cols="12" sm="6" md="6">
        <div class="d-flex align-center">
          <v-text-field v-model="fields[index]" label="Write something ..."></v-text-field>
          <v-btn @click="removeField(index)" icon>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn @click="addField">Add Field</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, String],
      default: () => ['']
    }
  },
  data () {
    return {
      fields: []
    }
  },
  mounted () {
    if (typeof this.value === 'string') {
      this.fields = this.value.split(' || ') || []
    } else if (Array.isArray(this.value)) {
      this.fields = this.value
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.fields = newValue || []
      },
      immediate: true
    },
    fields: {
      handler (newFields) {
        this.$emit('input', newFields)
      },
      deep: true
    }
  },
  methods: {
    addField () {
      this.fields.push('')
    },
    removeField (index) {
      this.fields.splice(index, 1)
    }
  }
}
</script>
