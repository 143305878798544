<template>
    <MoleculeContent />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MoleculeContent from '../molecules/MoleculeContent.vue'

export default defineComponent({
  components: {
    MoleculeContent
  }
})
</script>
