import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { user } from './user'
import { RootState } from './types'
import { global } from './global'
import { customerVoice } from './customer-voices'
import { project } from './projects'
import { websiteType } from './website-types'
import { projectType } from './project-types'
import { dashboard } from './dashboard'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    test: 'test'
  },
  modules: {
    global,
    user,
    customerVoice,
    project,
    websiteType,
    projectType,
    dashboard
  }
}

export default new Vuex.Store<RootState>(store)
