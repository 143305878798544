<template>
  <v-navigation-drawer
    :value="$vuetify.breakpoint.mobile && isDrawerOpen === true ? false : isDrawerOpen"
    app
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          <v-icon>mdi-api</v-icon> CMS
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="{ name: item.title }"
        active-class="highlighted"
        :class="item.title === $route.name ? 'highlighted' : ''"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title >{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  computed: {
    ...mapGetters({
      isDrawerOpen: 'global/isDrawerOpen'
    })
  },
  data () {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Customer Voices', icon: 'mdi-account-tie-voice-outline' },
        { title: 'Website Types', icon: 'mdi-web' },
        { title: 'Project Types', icon: 'mdi-book-information-variant' },
        { title: 'Projects', icon: 'mdi-book-outline' }
      ]
    }
  }
})
</script>
