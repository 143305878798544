const version = 'v1'
const routes: { baseUrl: string, image: string, login: string; logout: string; me: string; customerVoices: string; websiteTypes: string; projectTypes: string; projects: string, dashboard: string} = {
  baseUrl: process.env.VUE_APP_BACKEND_URI,
  image: `/api/${version}/images`,
  login: `/api/${version}/auth/login`,
  logout: `/api/${version}/auth/logout`,
  me: `/api/${version}/auth/me`,
  customerVoices: `/api/${version}/customer-voices`,
  websiteTypes: `/api/${version}/website-types`,
  projectTypes: `/api/${version}/project-types`,
  projects: `/api/${version}/projects`,
  dashboard: `/api/${version}/dashboard`
}

export default routes
