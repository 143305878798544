<template>
  <div class="py-12 mx-auto text-center">
    <div class="text-h6 pb-2">Project Type Breakdown</div>
    <Doughnut
      v-if="!isTableLoading"
      chart-id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
      class="mx-auto"
      height="500"
      width="500"
    />

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)

export default defineComponent({
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  computed: {
    ...mapGetters({
      isTableLoading: 'global/isTableLoading'
    })
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    chartData: {
      type: [Object]
    },
    chartOptions: {
      type: [Object]
    }
  }
})
</script>
