<template>
  <OrganismLayout />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrganismLayout from './organisms/OrganismLayout.vue'

export default defineComponent({
  name: 'LoginIndex',
  components: {
    OrganismLayout
  }
})
</script>
