import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import { CustomerVoiceState } from './types'
import { CustomerVoice } from '../models/customer-voice'
import { AxiosResponse } from 'axios'
import instance from '@/plugins/axios'
import routes from '@/utils/routes'
import { Pagination } from '@/store/models/pagination'

const state: CustomerVoiceState = {
  customerVoices: [],
  pagination: {} as Pagination
}

const getters: GetterTree<CustomerVoiceState, RootState> = {
  getCustomerVoices (state): Array<CustomerVoice> {
    return state.customerVoices
  },
  getPagination (state): Pagination | Record<string, never> {
    return state.pagination
  }
}

const mutations: MutationTree<CustomerVoiceState> = {
  SET_CUSTOMER_VOICES (state, payload: Array<CustomerVoice>) {
    state.customerVoices = payload
  },
  SET_PAGINATION (state, payload: Pagination) {
    state.pagination = payload
  },
  DELETE_CUSTOMER_VOICE (state, payload: number) {
    state.customerVoices = state.customerVoices.filter((customerVoice) => customerVoice.id !== payload)
    if (state.pagination) {
      state.pagination.total = (state.pagination.total || 0) - 1
    }
  }
}

const actions: ActionTree<CustomerVoiceState, RootState> = {
  async fetchCustomerVoices ({ commit }, params: object): Promise<AxiosResponse> {
    const response = await instance.get(routes.customerVoices, { params })
    commit('SET_CUSTOMER_VOICES', response.data.data)
    commit('SET_PAGINATION', response.data.pagination)
    return response
  },
  async saveCustomerVoice (_, data: CustomerVoice): Promise<AxiosResponse> {
    const response = await instance.post(routes.customerVoices, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  },
  async deleteCustomerVoice ({ commit }, id: number): Promise<AxiosResponse> {
    const response = await instance.delete(`${routes.customerVoices}/${id}`)
    commit('DELETE_CUSTOMER_VOICE', id)
    return response
  }
}

export const customerVoice: Module<CustomerVoiceState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
