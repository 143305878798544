import { NavigationGuardNext, Route } from 'vue-router'
import store from '@/store'

export const AUTHENTICATION = (to: Route, from: Route, next: NavigationGuardNext) => {
  const isAuthenticated = store.getters['user/isAuthenticated']
  const getUser = store.getters['user/getUser']

  if (!isAuthenticated && !getUser) {
    if (to.name !== 'Login') {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else if (to.path === '/' && (isAuthenticated && getUser)) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
}
