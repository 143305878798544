<template>
  <el-tiptap
    :extensions="extensions"
    v-model="content"
    placeholder="Write something ..."
  />
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Image,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  HardBreak,
  History,
  Fullscreen
} from 'element-tiptap'

export default {
  props: {
    editContent: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    extensions: [
      new Doc(),
      new Text(),
      new Paragraph(),
      new Heading({ level: 5 }),
      new Bold({ bubble: true }),
      new Underline({ bubble: true }),
      new Italic({ bubble: true }),
      new Strike({ bubble: true }),
      new Image(),
      new Blockquote(),
      new TextAlign(),
      new ListItem(),
      new BulletList({ bubble: true }),
      new OrderedList({ bubble: true }),
      new TodoItem(),
      new TodoList(),
      new Indent(),
      new HardBreak(),
      new Fullscreen(),
      new History()
    ],
    content: ''
  }),
  watch: {
    content: {
      handler: function (newVal) {
        this.$emit('content', newVal)
      },
      deep: true
    },
    editContent: {
      handler (newVal) {
        if (newVal) {
          this.content = newVal
        }
      },
      immediate: true
    }
  }
}
</script>
