<template>
  <v-card>
    <OrganismContent />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrganismContent from './organisms/OrganismContent.vue'

export default defineComponent({
  name: 'DashboardIndex',
  components: {
    OrganismContent
  }
})
</script>
